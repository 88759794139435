'use client';

import React, { useEffect, useState } from "react";
import { Button } from "./Button";
import moment from "moment";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  time?: number;
};

/**
 * TimeoutModal
 *
 * 1h - 3600000 ms
 * 2h - 7200000 ms
 * 1 min - 60000 ms
 * 10 min - 600000 ms
 * 1 month - 2629746000 ms
 */
export const TimeoutModal: React.FC<Props> = ({ time = 3600000 }) => {
  const [show, setShow] = useState(false);

  const duration = moment.duration(time, 'milliseconds');

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const clear = () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
    };

    const startTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setShow(true);
        document.body.style.overflow = 'hidden';
        clear();
      }, time);
    };

    const handleUserActivity = () => {
      startTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);

    startTimer();

    return clear;
  }, [time]);

  const onClick = () => window.location.reload();

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="fixed inset-0 z-[99] bg-black/20 backdrop-blur-md grid justify-center items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="grid gap-6 justify-items-center w-[90svw] lg:w-[550px]">
            <p className="text-32 text-center">{`You've been inactive for more than ${Math.floor(duration.asMinutes())} min. Please refresh the page.`}</p>
            <Button label="Refresh" variant="primary" size="large" onClick={onClick} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
